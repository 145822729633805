import { Title } from '@solidjs/meta';
import { For, mergeProps } from 'solid-js';
import * as icons from '@troon/icons';
import { Button, addToast, useFocusable } from '@troon/ui';
import type { ParentProps, JSX, Component } from 'solid-js';

export default function IconGuide() {
	return (
		<>
			<Title>Icons / Styleguide</Title>

			<h1 class="mb-8 text-4xl font-semibold">Icons</h1>

			<div class="grid grid-cols-12 gap-4 text-neutral-800">
				<For each={Object.keys(icons)}>
					{(icon) => {
						if (icon === 'Icon' || !icon.startsWith('Icon')) {
							return null;
						}

						// @ts-expect-error
						// eslint-disable-next-line import/namespace
						const Icon = icons[icon] as Component;
						return (
							<StyleCard
								title={icon}
								onClick={() => {
									window.navigator.clipboard.writeText(`<${icon} />`);
									addToast(`Copied ${icon} to clipboard!`, { timeout: 2000, variant: 'positive' });
								}}
							>
								<Icon />
							</StyleCard>
						);
					}}
				</For>
			</div>
		</>
	);
}

function StyleCard(inputProps: ParentProps<{ title: string; class?: string } & JSX.HTMLAttributes<HTMLButtonElement>>) {
	const focusProps = useFocusable();
	const props = mergeProps(inputProps, focusProps);
	return (
		<Button
			appearance="secondary-current"
			{...props}
			class="overflow-hiddenmd:col-span-3 col-span-6 flex flex-row-reverse flex-nowrap items-center justify-end gap-2 normal-case lg:col-span-2"
		>
			<div class="shrink overflow-hidden text-ellipsis text-sm">{props.title}</div>
			<div class="flex shrink-0 grow-0 flex-col items-stretch justify-center gap-4 text-xl">{props.children}</div>
		</Button>
	);
}
