import { Show, splitProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { IconProps } from '../props';

export function IconShareAndroid(props: IconProps) {
	const [, svgProps] = splitProps(props, ['title']);

	return (
		<svg
			viewBox="0 0 24 24"
			height="1.2em"
			width="1.2em"
			fill="none"
			stroke="none"
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<Show when={props.title}>
				<title>{props.title}</title>
			</Show>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m9 13.5l6 3m0-9l-6 3M18 21a3 3 0 1 1 0-6a3 3 0 0 1 0 6M6 15a3 3 0 1 1 0-6a3 3 0 0 1 0 6m12-6a3 3 0 1 1 0-6a3 3 0 0 1 0 6"
			/>
		</svg>
	);
}
